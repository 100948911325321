import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

export const providerOptions = {
  walletlink: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "Bored Ape Pixel Club V3", // Required
      // alchemyId: process.env.REACT_APP_ALCHEMY_KEY
      infuraId: process.env.REACT_APP_INFURA_KEY // Required unless you provide a JSON RPC url; see `rpc` below
    }
  },
  walletconnect: {
    package: WalletConnect, // required
    options: {
      // alchemyId: process.env.REACT_APP_ALCHEMY_KEY
      infuraId: process.env.REACT_APP_INFURA_KEY // required
    }
  }
};
