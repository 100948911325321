import { ethers } from 'ethers';

import BoredApePixelClubV3 from "../assets/abi/BoredApePixelClubV3.json";
import { ABI, SMART_CONTRACT_ADDRESS } from './constants';

export function getContract(provider:any){

    if(!provider) return null;

    const contractAddress = SMART_CONTRACT_ADDRESS;
    const abi = ABI || BoredApePixelClubV3?.abi;

    // const provider = new ethers.providers.Web3Provider(window.ethereum);
    
    // const abi = JSON.parse(fs.readFileSync('../../../hardhat-bapc/artifacts/contracts/BAPCv3.sol/BoredApePixelClubV3.json', 'utf-8'))


    let p;
    try{
        p = provider.getSigner();
    }catch(err){
        p = provider;
    }

    const bapc = new ethers.Contract(contractAddress, abi, p);

    return bapc;

}
