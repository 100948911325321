// @ts-nocheck

import React, {useEffect, useState} from 'react';
import logo from '../src/assets/img/BAPC_leaking.png';
import twitter from '../src/assets/img/twitter.svg';
import discord from '../src/assets/img/discord.svg';
import opensea from '../src/assets/img/opensea.svg';
import aura from '../src/assets/img/aura.png';
import LoadingButton from '@mui/lab/LoadingButton';
import './App.css';
import Alert from '@mui/material/Alert';
import { ethers, BigNumber, Contract } from "ethers";
import Web3Modal from "web3modal";
import { networkParams } from './utils/networks';
import { providerOptions } from './utils/providers';
import { getContract } from './utils/contract';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { Button } from '@mui/material';
import { MAX_SUPPLY, NETWORK } from './utils/constants';
import { toHex, truncateAddress } from './utils/utils';

import { OPENSEA_URL, AURA_URL, TWITTER_URL, DISCORD_URL } from './utils/constants';
import { VariantType, useSnackbar } from 'notistack';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

import SaveIcon from '@mui/icons-material/Save';
import styled from "styled-components";
import Badge from '@mui/material/Badge';

export const StyledButton = styled.button`
  font-family: 'coder';
  padding: 10px;
  font-size: 1rem;
  border-radius: 6px;
  border: none;
  background-color: red;
  padding: 10px;
  letter-spacing: 2px;
  font-weight: bold;
  color: white;
  width: 270px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px black;
  -webkit-box-shadow: 0px 6px 0px -2px black;
  -moz-box-shadow: 0px 6px 0px -2px black;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    color: silver;
  }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }
`;


export const StyledButtonConnect = styled.button`
  font-family: 'coder';
  padding: 10px;
  font-size: 1rem;
  border-radius: 6px;
  border: none;
  background-color: black;
  padding: 10px;
  letter-spacing: 2px;
  font-weight: bold;
  color: white;
  width: 270px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px black;
  -webkit-box-shadow: 0px 6px 0px -2px black;
  -moz-box-shadow: 0px 6px 0px -2px black;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    color: silver;
  }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }
`;


export const WalletBox = styled.div`
  text-decoration: none;
  border-radius: 10px;
  border: 2px solid white;
  background-color: transparent;
  //padding: 10px;
  font-weight: bold;
  font-size: 15px;
  width: 216px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px white;
  -webkit-box-shadow: 0px 4px 0px -2px white;
  -moz-box-shadow: 0px 4px 0px -2px white;
  @media (max-width: 565px) {
    margin-top: 20px;
  
`;


export const StyledLogo = styled.img`

width: 1600px;
padding-top: 20px;
padding-bottom:0px;

@media all and (max-width: 1800px) {
  width: 1400px !important;
}

@media all and (max-width: 1700px) {
  width: 1200px !important;
}

@media all and (max-width: 1200px) {
  width: 950px !important;
}

@media all and (max-width: 1000px) {
  width: 850px !important;
}

@media all and (max-width: 800px) {
  width: 650px !important;
}

@media all and (max-width: 600px) {
  width: 500px !important;
  padding-top: 40px;
}

@media all and (max-width: 480px) {
  width: 350px !important;
  padding-top: 40px;
}

  transition: width 0.5s;
  transition: height 0.5s;
`;


const web3Modal = new Web3Modal({
  providerOptions // required
});


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
    },
  },
});


function App() {

  const { enqueueSnackbar } = useSnackbar();

  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [account, setAccount] = useState();
  const [signature, setSignature] = useState("");
  const [error, setError] = useState("");
  const [chainId, setChainId] = useState();
  const [network, setNetwork] = useState();
  const [message, setMessage] = useState("");
  const [signedMessage, setSignedMessage] = useState("");
  const [verified, setVerified] = useState();
  const [balance, setBalance] = useState("");
  const [walletName, setWalletName] = useState("");
  const [maxPerWallet, setMaxPerWallet] = useState(19);

  // const [noCorrectNet, setNoCorrectNet] = useState("");

  const [mintPaused, setMintPaused] = useState(false);
  const [mintPrice, setMintPrice] = useState<BigNumber>(ethers.utils.parseEther('0.0'));
  const [tokensLeft, setTokensLeft] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);

  const [bapcContract, setBapcContract] = useState<Contract>();

  const [quantity, setQuantity] = useState<Number>(1);
  const [minted, setMinted] = useState(0);


  const [isMinting, setIsMinting] = useState(false);

  const [etherscanLink, setEtherscanLink] = useState('');
  const [soldOut, setSoldOut] = useState(false);


  //console.log("Network ", network);

  const connectWallet = async () => {
    try {
      
      const provider = await web3Modal.connect();
      

      const library = new ethers.providers.Web3Provider(provider);
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();


      setProvider(provider);
      setLibrary(library);
      const bapc = getContract(library);
      setBapcContract(bapc);
      if (accounts) setAccount(accounts[0]);

      setChainId(network.chainId);




      if(network.chainId.toString() !== NETWORK.toString() && network.chainId.toString() !== toHex(NETWORK)){

        // setNoCorrectNet("You need to select Ethereum mainnet in order to be able to mint");
        enqueueSnackbar(`You need to select Ethereum mainnet in order to be able to mint.`, { variant : 'warning'});
        disconnect();
        

      }

      


    } catch (error) {
      setError(error);
      /* const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);*/
      if(error && String(error).includes('User Rejected'))
      if (typeof window.ethereum !== "undefined") {
        let provider = window.ethereum;
        // edge case if MM and CBW are both installed
        if (window.ethereum.providers?.length) {
          window.ethereum.providers.forEach(async (p) => {
            // We select metamask as default and try to requestPermission for metamask.
            if (p.isMetaMask) provider = p;
          });
        }

        await (new ethers.providers.Web3Provider(provider)).provider.request({
          method: "wallet_requestPermissions",
          params: [{ eth_accounts: {} }],
        });
      }
    }
  };

  const handleNetwork = (e) => {
    const id = e.target.value;
    setNetwork(Number(id));
  };

  const handleInput = (e) => {
    const msg = e.target.value;
    setMessage(msg);
  };

  const switchNetwork = async () => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(network) }]
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [networkParams[toHex(network)]]
          });
        } catch (error) {
          setError(error);
        }
      }
    }
  };

  const signMessage = async () => {
    if (!library) return;
    try {
      const signature = await library.provider.request({
        method: "personal_sign",
        params: [message, account]
      });
      setSignedMessage(message);
      setSignature(signature);
    } catch (error) {
      setError(error);
    }
  };

  const verifyMessage = async () => {
    if (!library) return;
    try {
      const verify = await library.provider.request({
        method: "personal_ecRecover",
        params: [signedMessage, signature]
      });
      setVerified(verify === account.toLowerCase());
    } catch (error) {
      setError(error);
    }
  };

  const refreshState = () => {
    setAccount();
    setChainId();
    setNetwork("");
    setMessage("");
    setSignature("");
    setBalance("");
    setVerified(undefined);
    setIsMinting(false);
    setEtherscanLink('');
  };

  const disconnect = async () => {
    await web3Modal.clearCachedProvider();
    refreshState();
    if(bapcContract){
      bapcContract.removeAllListeners('Minted');
      bapcContract.removeAllListeners('MintPaused');
      bapcContract.removeAllListeners('MintPriceChanged');
    }
  };

  const mint = async () => {


    if(!quantity || quantity <= 0) return;

    try{
    
      const bapc = getContract(library);
      const amount = mintPrice.mul(BigNumber.from(quantity));
      const userBalance:BigNumber = await library.getBalance(account);
      if(userBalance.lt(amount)){
        enqueueSnackbar(`Insufficient funds ETH ${ethers.utils.formatEther(userBalance)} to mint a total of ETH ${ethers.utils.formatEther(amount)}`, {variant : 'info'});
        return;
      }
      
      setIsMinting(true);
      setEtherscanLink('');
      const tx = await bapc.mint(quantity, {value: amount});
      const txReceipt = await tx.wait(1);

      const etherscanLink = `https://${NETWORK === 5 ? 'goerli.' : ''}etherscan.io/tx/${txReceipt.transactionHash}`;
      setEtherscanLink(etherscanLink);
      enqueueSnackbar(`You minted ${quantity} Bored Ape Pixel Club V3.`, {variant : 'success'});
      
      setQuantity(1);
      setIsMinting(false);
    }catch(err){
      setIsMinting(false);

      const string = JSON.stringify(err).toLowerCase();
      if(string){
        if(string.includes('User Rejected'.toLowerCase()))
            enqueueSnackbar(`User Rejected the request`, {variant : 'info'});

        if(string.includes('execution reverted: Not enough balance for mint the quantity supplied'.toLowerCase()))
        {
          
          enqueueSnackbar(`Not enough balance for mint the quantity supplied.`, {variant : 'info'});
        }
        if(string.includes('execution reverted: Can not mint this many'.toLowerCase()))
        {
          
          enqueueSnackbar(`Can not mint this many.`, {variant : 'info'});
        }
        if(string.includes('execution reverted: Not enough supply left for this mint quantity'.toLowerCase()))
        {

          enqueueSnackbar(`Not enough supply left for this mint quantity.`, {variant : 'info'});
        }
        if(string.includes('execution reverted: Mint is paused'.toLowerCase()))
        {
          setMintPaused(true);

          enqueueSnackbar(`Mint is paused.`, {variant : 'info'});
        }
      }
    }

    setMinted((await bapcContract.balanceOf(account)).toNumber());
  }

  useEffect(() => {
    init();

    async function init() {
      await web3Modal.clearCachedProvider();
    }
  }, []); 

  useEffect(() => {
      setMintindInitData();

    async function setMintindInitData() {

      const bapc = bapcContract ?? getContract(new ethers.providers.AlchemyProvider(NETWORK === 1 ? 'mainnet' : 'goerli', process.env.REACT_APP_ALCHEMY_KEY));
      if (bapc) {

        if(!bapcContract)
          setBapcContract(bapc);

        bapc.removeAllListeners('Minted');
        bapc.removeAllListeners('MintPaused');
        bapc.removeAllListeners('MintPriceChanged');

        setMaxPerWallet((await bapc.maxMintPerWallet()).toNumber())

        const totalSupply: BigNumber = await bapc.totalSupply();
        setTotalSupply(totalSupply);
        setMintPaused(await bapc.mintPaused());
        setMintPrice(await bapc.mintPrice());

        bapc.on('Minted', (address, quantity, totalSupply) => {

          setTotalSupply(totalSupply)
          setTokensLeft(MAX_SUPPLY - totalSupply);
        });

        bapc.on('MintPaused', (isPaused) => {

          setMintPaused(isPaused);
        });

        bapc.on('MintPriceChanged', (newPrice) => {

          setMintPrice(newPrice);
        });
      }
    }

  }, [])

  useEffect(() => {
    if(totalSupply >= MAX_SUPPLY){
      setSoldOut(true);
    }
  }, [totalSupply])
  

  useEffect(() => {
    if(account)
      accountData();

    async function accountData() {
      const balance = library.getBalance(account)
         .then((balance) => {

             const balanceInEth = ethers.utils.formatEther(balance)

             setBalance(balanceInEth);
         })
         
      if(bapcContract)
        setMinted((await bapcContract.balanceOf(account)).toNumber());
    }
  }, [bapcContract, account])
  
  

  useEffect(() => {
    if (provider?.on) {
      setWalletName(provider.isMetaMask ? 'Metamask' : provider.isCoinbaseWallet ? 'Coinbase' : 'WalletConnect')
      const handleAccountsChanged = (accounts) => {
        if (accounts) setAccount(accounts[0]);

      };

      const handleChainChanged = (_hexChainId) => {
        setChainId(_hexChainId);

        if(_hexChainId.toString() !== NETWORK.toString() && _hexChainId.toString() !== toHex(NETWORK)){

          enqueueSnackbar(`You need to select Ethereum mainnet in order to be able to mint.`, { variant : 'warning'});
          
          disconnect();
         
        }


      };

      const handleDisconnect = () => {
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);


  /*
    Buttons
  
  */
    function incrementValue() {
      if(quantity + 1 + minted <= maxPerWallet
        // && quantity + 1 <= tokensLeft
        ){
        setQuantity(quantity+1);
      }//else        
        // setQuantity(maxPerWallet-minted);
    }
    
    function decrementValue() {
      if(quantity - 1 >= 1 ){
        setQuantity(quantity-1);
      }else        
        setQuantity(1);
    }
  
  function handleChange(event) {


    try{
      BigNumber.from(event.target.value);
      setQuantity(event.target.value);
    }catch(e){
      setQuantity(1);
    }    
  }


  return (
    <>
        <ThemeProvider theme={darkTheme}>
          <AppBar position="fixed" color="primary" sx={{flexDirection : 'row-reverse'}}>
            <Toolbar >
              <Stack direction="row" spacing={4}>
                <a href={TWITTER_URL} target="_blank" rel="noreferrer"><img src={twitter} alt="Twitter"/></a>
                <a href={DISCORD_URL} target="_blank" rel="noreferrer"><img src={discord} alt="Discord"/></a>
                {/* <a href={OPENSEA_URL} target="_blank" rel="noreferrer"><img src={opensea} alt="Opensea" width={40} height={45}/></a> */}
                <a href={AURA_URL} target="_blank" rel="noreferrer"><img src={aura} alt="Aura" width={40} height={45}/></a>
                <WalletBox>
                  {!account && <><Badge color="error" variant="dot" sx={{ m : 2 }}/> Not connected</> } 
                  {account && <><Badge color="success" variant="dot" sx={{ m : 2 }}/> { truncateAddress(account)}</> } 
                </WalletBox>
              </Stack> 
            </Toolbar>
          </AppBar>
        </ThemeProvider>
      
      <Container maxWidth="xl" sx={{ paddingTop: 5,   bgcolor: 'rgb(37, 37, 37)', justifyContent: 'center', textAlign:'center', height: '100%'}}>
        
        <Box sx={{ bgcolor: 'rgb(37, 37, 37)' }}>
          
          <div className="App">
            <header className="App-header">
            <StyledLogo
                src={logo}
                alt="BAPC"
                loading="lazy"
            />
      <Box
        sx={{
          boxShadow: 3,
          width: '100rem',
          height: account ? '35rem' : '28rem',
          bgcolor: (theme) => (theme.palette.mode === 'dark' ? 'rgb(37, 37, 37)' : 'rgb(37, 37, 37)'),
          color: (theme) =>
            theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF',
          p: 1,
          m: 1,
          // borderRadius: 2,
          textAlign: 'center',
          justifyContent: 'center',
          fontSize: '1.0rem',
          fontWeight: '900',
          border: '2px solid white',
          borderRadius: '40px',
          background: 'linear-gradient(90deg, rgba(92, 76, 84, 0.62) 10%, rgba(0, 0, 0, 0.62) 93%)'
        }}
      >


      {!account && <div style={{justifyContent: 'center', textAlign: 'center'}}>
        <span><br/></span>

        
        <span style={{letterSpacing: '0.3em', fontSize : '24px', lineHeight:1.6, fontWeight : 400}}>{totalSupply && totalSupply.toString()} | {MAX_SUPPLY} <br/><br/></span>
        
        <span style={{ background: 'white', borderRadius: '5px' , padding: '14px', color: 'black', letterSpacing: '2px', fontSize : '1rem', lineHeight:1.6, fontWeight : 'bold'}}>Price: &emsp;&emsp;&emsp;&emsp;&emsp; {ethers.utils.formatEther(mintPrice)} ETH</span><br/><br/>

         <div className="input-group">
          <input type="button" value="-" className="button-minus" data-field="quantity" onClick={decrementValue}/>
          <input type="number" step="1" max="" value={quantity} name="quantity" className="quantity-field" onChange={handleChange}/>
          <input type="button" value="+" className="button-plus" data-field="quantity" onClick={incrementValue}/>
        </div>

        <br />
        
        <span style={{letterSpacing: '2px', fontSize : '1rem', lineHeight:1.6, fontWeight : 'bold'}}>Total: &emsp;&emsp;&emsp;&emsp;&emsp;{ethers.utils.formatEther(mintPrice.mul(BigNumber.from(quantity)))} ETH</span> <br/><br/><br/>


        {!mintPaused && !soldOut && <StyledButtonConnect onClick={connectWallet} variant="contained">
                        Connect wallet  
              </StyledButtonConnect> }

        {soldOut && <StyledButton>
            SOLD OUT  
          </StyledButton>}

          {mintPaused && !soldOut && <StyledButton>
                        MINT IS PAUSED
              </StyledButton> }

              <br/> <br/>

        { !isMinting && 
          <div id='testCrossMint' style={{display : 'inline-block'}}>
            <crossmint-pay-button
                clientId={process.env.REACT_APP_CROSSMINT_CLIENT_ID}
                environment={NETWORK === 1 ? 'production' : 'staging'}
                mintConfig={`{ "quantity": "${quantity}", "totalPrice": "${ethers.utils.formatEther(mintPrice.mul(BigNumber.from(quantity)))}"}`}
                
            />
          </div>
         }


        <br/><br/><br/><br/>

              
      </div>} 

      {account && <div>
        <br/><br/>
        
        <span style={{letterSpacing: '0.3em', fontSize : '24px', lineHeight:1.6, fontWeight : 400}}>{totalSupply && totalSupply.toString()} | {MAX_SUPPLY} <br/><br/></span>
        
        <span style={{ background: 'white', borderRadius: '5px' , padding: '14px', color: 'black', letterSpacing: '2px', fontSize : '1rem', lineHeight:1.6, fontWeight : 'bold'}}>Price: &emsp;&emsp;&emsp;&emsp;&emsp; {ethers.utils.formatEther(mintPrice)} ETH</span><br/><br/><br/>
        
         {!mintPaused && <div>

          { minted!== maxPerWallet && <div>

            <div className="input-group">
              <input type="button" value="-" className="button-minus" data-field="quantity" onClick={decrementValue}/>
              <input type="number" step="1" max="" value={quantity} name="quantity" className="quantity-field" onChange={handleChange}/>
              <input type="button" value="+" className="button-plus" data-field="quantity" onClick={incrementValue}/>
            </div>

            <br />
            <span style={{letterSpacing: '2px', fontSize : '1rem', lineHeight:1.6, fontWeight : 'bold'}}>Total: &emsp;&emsp;&emsp;&emsp;&emsp;{ethers.utils.formatEther(mintPrice.mul(BigNumber.from(quantity)))} ETH</span> <br/><br/><br/>

             { !isMinting && minted!== maxPerWallet && <StyledButton onClick={mint}>
                  Mint 
              </StyledButton>  }

          {isMinting && <LoadingButton
            sx={{color:'white', backgroundColor: 'white'}}
            loading
            loadingPosition="start"
            variant="outlined"
            startIcon={<SaveIcon />}
          >
            Minting...
          </LoadingButton> } 
          </div> } 

              <br/>
              <p>Max {maxPerWallet} per Wallet</p>
              <p>This wallet { truncateAddress(account)} has {minted} Minted</p>
             
          </div>
          }
          
          {soldOut && <StyledButton>
            SOLD OUT  
          </StyledButton>}

          {mintPaused && !soldOut && <StyledButton>
                        MINT IS PAUSED
              </StyledButton> }

              {/* account && <Button onClick={disconnect} variant="outlined">
                  Disconnect wallet
        </Button> */}
      </div> }
      </Box>


     {etherscanLink && <Alert severity="success">
        <a href={etherscanLink} target="_blank" rel="noreferrer">{etherscanLink}</a>
      </Alert> }

            </header>
            <br/><br/>
            <footer className='footer'>
              Copyright © 2022  Bored Ape Pixel Club
            </footer>
          </div>
        </Box>
      </Container>
    </>
  );
}

export default App;
